import React, { ReactElement } from 'react'
import styled from 'styled-components'

const ContainerDiv = styled.div`
    margin-top: 2em;
    font-size: 80%;
    display: flex;
    justify-content: left;
    align-items: center;
`

export default function PublicDomainCreditsContainer(): ReactElement {
    return (
        <ContainerDiv>
            <a rel="license" href="https://creativecommons.org/publicdomain/mark/1.0/">
                <img src="https://i.creativecommons.org/p/mark/1.0/88x31.png" alt="Public Domain Mark" />
            </a>
            <div>
                All pictures are free of known copyright restrictions.
            </div>
        </ContainerDiv>
    )
}
