import React, { ReactElement, useEffect } from 'react'
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
// import FactsContainer from './FactsContainer';
// import withAppInsights from './AppInsights';
import PublicDomainCreditsContainer from '../components/PublicDomainCreditsContainer';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { FacebookProvider, Comments } from 'react-facebook';
import useSiteMetadata from '../queries/QSiteMetadata';
import SocialMediaButtons from '../components/SocialMediaButtons';

const FullViewGridContainer = styled.div`
  display: grid;
    
  grid-template-areas: 
    "article-content"
  ;

  @media only screen and (min-width: 992px) { 
    grid-template-columns: 1fr 2fr 1fr;

    grid-template-areas: 
      "left-side  article-content right-side";
  }
`;

const FullViewArticleContainer = styled.div`
  grid-area: article-content;
`;

const ArticleH2 = styled.h1`
  color: #333;
  flex-shrink: 0;
  font-size: 1.8em;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 1em;
  margin-bottom: 0.3em;
`

const ShortVersionContainer = styled.div`
  margin-bottom: 1em;
`

const AuthorContainer = styled.div`
    margin-bottom: 2em;
    font-size: 80%;
`

const ArticleContentContainer = styled.div`
    & p {
        margin-bottom: 0.5em;
    }

    & h3 {
        font-weight: bold;
        font-size: 125%;
    }

    & em {
      font-style: italic;
    }

    & strong {
      font-weight: bold;
    }

    blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: '\\201C''\\201D''\\2018''\\2019';
}
blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote p {
  display: inline;
}
`

const ThumbprintContainer = styled.figure`
    margin-bottom: 1.5em;
    font-style: italic;

    & img {
        width: 95%;
    }
`

const DisclaimerContainer = styled.div`
    font-style: italic;
    font-size: 80%;
`

const fullArticle = function ArticleFullView({ data, location }): ReactElement {
  const fullArticle = data.cosmicjsArticles;

  const { siteMetadata } = useSiteMetadata();
  const { siteURL } = siteMetadata;

  useEffect(() => {
    // const realCall = async (slug: string) => {
    //   await fetch(`/api/article/${slug}/read`, {
    //     method: 'post',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: ""
    //   });
    // };

    // realCall(data.cosmicjsArticles.slug);
  }, [])

  return (
    <Layout>
      <FullViewGridContainer>
        <FullViewArticleContainer>
          <ArticleH2>{fullArticle.title}</ArticleH2>
          <SocialMediaButtons url={`${siteURL}${location.pathname}`} />
          <ShortVersionContainer> {fullArticle.metadata.short_version}</ShortVersionContainer>
          {/* <AuthorContainer>By {fullArticle.metadata.author}</AuthorContainer> */}
          <ThumbprintContainer>
            <img src={fullArticle.metadata.thumbnail.imgix_url} alt={fullArticle.metadata.thumbnailcaption} />
            <figcaption>{fullArticle.metadata.thumbnailcaption}</figcaption>
          </ThumbprintContainer>
          <ArticleContentContainer>
            {ReactHtmlParser(fullArticle.content)}
          </ArticleContentContainer>
          <DisclaimerContainer>{fullArticle.metadata.disclaimer}</DisclaimerContainer>
          {/* <FactsContainer facts={fullArticle.metadata.facts}></FactsContainer> */}
          <PublicDomainCreditsContainer />
          <FacebookProvider appId="785801405321247">
            <Comments href={`${siteURL}${location.pathname}`} />
          </FacebookProvider>
        </FullViewArticleContainer>
      </FullViewGridContainer>
    </Layout >
  )
}

export default fullArticle;

export const pageQuery = graphql`
  query ArticlesBySlug($slug: String!) {
    cosmicjsArticles(slug: { eq: $slug }) {
      slug
      id
      content
      title
      created(formatString: "MMMM DD, YYYY")
      metadata {
        thumbnail {
          imgix_url
        }
        thumbnailcaption
        short_version
      }
    }
  }
`