import React, { ReactElement } from 'react'
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon, RedditShareButton, RedditIcon } from 'react-share'
import styled from 'styled-components';

interface Props {
    url: string,
    inverseColors?: boolean
}

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    /* padding: 0.2em; */
    margin-top: 1em;
    margin-bottom: 1em;
`;

const ButtonContainer = styled.div`
    padding-left: 1em;

    @media screen and (min-width: 992px) {
        padding-left: 0.8em;
    }
`;

export default function SocialMediaButtons(props: Props): ReactElement {
    return (
        <ButtonsContainer>
            <ButtonContainer>
                <FacebookShareButton url={props.url}>
                    <FacebookIcon iconFillColor={props.inverseColors ? "rgb(59, 89, 152)" : undefined} bgStyle={props.inverseColors ? { "fill": "white" } : undefined} size={32} round={true}></FacebookIcon>
                </FacebookShareButton>
            </ButtonContainer>
            <ButtonContainer>
                <TwitterShareButton url={props.url}>
                    <TwitterIcon size={32} round={true}></TwitterIcon>
                </TwitterShareButton>
            </ButtonContainer>
            <ButtonContainer>
                <WhatsappShareButton url={props.url}>
                    <WhatsappIcon size={32} round={true}></WhatsappIcon>
                </WhatsappShareButton>
            </ButtonContainer>
            <ButtonContainer>
                <RedditShareButton url={props.url}>
                    <RedditIcon size={32} round={true}></RedditIcon>
                </RedditShareButton>
            </ButtonContainer>
        </ButtonsContainer>
    )
}
